import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { Indicator } from '@brainstud/ui/Loaders/Indicator';
import { useDebounce } from '@brainstud/universal-components/Hooks/useDebounce';
import { useOnClickOutside } from '@brainstud/universal-components/Hooks/useOnClickOutside';
import { useSyncRefs } from '@brainstud/universal-components/Hooks/useSyncRefs';
import { Clear } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { isPromise } from 'Utils/isPromise';
import { AutocompleteInputProps } from './AutocompleteInputProps';
import {
  AutocompleteInputReducer,
  InitialAutocompleteState,
} from './AutocompleteInputReducer';
import styles from './AutocompleteInput.module.css';

const cx = classNames.bind(styles);

/**
 * AutocompleteInput.
 *
 * An Input that shows a list of options to choose from based on the provided `data` property. When selected
 * it will show as a tag in the input. The `data` property can handle an object or works with promises returning
 * an object.
 *
 * _Note: The input is currently not linked to the `Form` component._
 */
export const AutocompleteInput = React.forwardRef<
  HTMLInputElement,
  AutocompleteInputProps
>(
  (
    {
      id,
      name,
      data,
      style,
      className,
      size,
      inline,
      loading,
      label,
      placeholder,
      defaultValue = {},
      tabIndex,
      readOnly,
      disabled,
      multiple = false,
      children,
      autoComplete,
      onBlur,
      onFocus,
      onChange,
      onInput,
      onInvalid,
      onReset,
      onSubmit,
      onContextMenu,
    },
    ref
  ) => {
    const inputElement = useSyncRefs(ref, null);
    const selectionRef = useRef<HTMLDivElement>(null);
    const [t] = useTranslator();
    const identifier = typeof id === 'number' ? `${name}_${id}` : id || name;
    const [
      {
        options: { filtered: suggestions },
        values,
        inputValue,
        isDrawerOpen,
        isFetching,
        error,
      },
      dispatch,
    ] = useReducer(AutocompleteInputReducer, {
      ...InitialAutocompleteState,
      values: defaultValue,
    });

    const debouncedControlledValue = useDebounce(inputValue, 600);
    const isLoading = loading || isFetching;

    useEffect(() => {
      if (!data) return;
      if (typeof data === 'object' && !isPromise(data)) {
        dispatch({
          type: 'SET_OPTIONS',
          payload: data,
        });
      }
    }, [data]);

    const isMounted = useRef<boolean>();
    useEffect(() => {
      isMounted.current = true;
      if (
        (typeof data === 'function' || isPromise(data)) &&
        debouncedControlledValue &&
        debouncedControlledValue.length > 0
      ) {
        dispatch({ type: 'START_FETCHING' });
        (isPromise(data) ? data : data(debouncedControlledValue))
          .then((result) => {
            if (isMounted.current) {
              dispatch({
                type: 'SET_OPTIONS',
                payload: result,
              });
            }
          })
          .catch((apiError: Error) => {
            if (isMounted.current) {
              dispatch({
                type: 'SET_API_ERROR',
                payload: apiError,
              });
            }
          });
      }
      return () => {
        isMounted.current = false;
      };
    }, [debouncedControlledValue, data]);

    const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        dispatch({
          type: 'CHANGE_INPUT_VALUE',
          payload: event.target.value,
        });
      },
      []
    );

    const handleRemoveItem = useCallback(
      (key) => {
        dispatch({
          type: 'REMOVE_VALUE',
          payload: key,
        });
        inputElement.current?.focus();
      },
      [inputElement]
    );

    const handleSuggestionClick = useCallback(
      (key: string, suggestion: string) => {
        dispatch({
          type: 'ADD_VALUE',
          payload: {
            [key]: suggestion,
          },
        });
        inputElement.current?.focus();
      },
      [inputElement]
    );

    useEffect(() => {
      onChange?.(Object.keys(values), values);
    }, [values]);

    useOnClickOutside(selectionRef, () => {
      dispatch({
        type: 'CLOSE_DRAWER',
      });
    });

    const handleReset = useCallback(() => {
      dispatch({ type: 'RESET_INPUT' });
    }, []);

    const handleKeyDown = useCallback<
      KeyboardEventHandler<HTMLInputElement | HTMLButtonElement>
    >((event) => {
      switch (event.key) {
        case 'Backspace':
          // @ts-ignore nodeName is available on event target.
          if (event.target.nodeName === 'INPUT') {
            if (event.currentTarget.value === '') {
              // @ts-ignore Focus method actually exists on previousSibling property.
              event.target.previousSibling?.focus?.();
            }
          } else if (event.currentTarget.dataset.value) {
            // prettier-ignore
            // @ts-ignore Focus method actually exists on previousSibling property.
            (event.target.previousSibling || event.target.nextSibling)?.focus?.();
            dispatch({
              type: 'REMOVE_VALUE',
              payload: event.currentTarget.dataset.value,
            });
          }
          break;
        case 'Escape':
          dispatch({ type: 'CLOSE_DRAWER' });
          break;
        default:
          return true;
      }
      return true;
    }, []);

    const canAddNewItem = multiple || Object.keys(values).length < 1;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        className={cx(
          styles.base,
          {
            'is-inline': size || inline,
            'is-disabled': disabled,
          },
          className
        )}
        style={{ ...style, width: size ? `${size}%` : undefined }}
        ref={selectionRef}
        onClick={() => inputElement.current?.focus()}
      >
        {label && (
          <label
            htmlFor={identifier}
            id={`${identifier}_label`}
            className={cx('label')}
          >
            {label}
          </label>
        )}
        <div className={cx(styles.wrapper, { 'has-children': !!children })}>
          <div className={cx(styles.matches)}>
            {Object.keys(values).map((key) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <span
                key={key}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                className={cx(styles.match)}
                data-value={key}
                onKeyDown={handleKeyDown}
              >
                <span>{values[key]}</span>
                <button
                  type="button"
                  tabIndex={-1}
                  onClick={() => handleRemoveItem(key)}
                  className={styles.removeButton}
                >
                  <Clear />
                </button>
              </span>
            ))}

            <input
              name={name}
              id={identifier}
              type="text"
              placeholder={placeholder}
              tabIndex={tabIndex}
              readOnly={readOnly || !canAddNewItem}
              disabled={disabled}
              autoComplete={autoComplete}
              onBlur={onBlur}
              value={
                ['string', 'number'].includes(typeof inputValue)
                  ? String(inputValue)
                  : ''
              }
              role="combobox"
              aria-expanded={isDrawerOpen}
              aria-controls={identifier}
              aria-label={!label && !!placeholder ? placeholder : undefined}
              aria-labelledby={label ? `${id}_label` : undefined}
              onFocus={onFocus}
              onChange={handleInputChange}
              onInput={onInput}
              onInvalid={onInvalid}
              onReset={onReset}
              onKeyDown={handleKeyDown}
              onSubmit={onSubmit}
              onContextMenu={onContextMenu}
              ref={inputElement}
              className={cx(styles.input, {
                'has-text':
                  inputElement.current && inputElement.current.value !== '',
              })}
            />
          </div>

          {isDrawerOpen && canAddNewItem && (
            <div className={cx(styles.suggestions)} role="listbox">
              {Object.keys(suggestions).length > 0 ? (
                Object.keys(suggestions).map((key) => (
                  <button
                    type="button"
                    role="option"
                    aria-selected={false}
                    onClick={() => handleSuggestionClick(key, suggestions[key])}
                    key={key}
                  >
                    {suggestions[key]}
                  </button>
                ))
              ) : (
                <div className={cx(styles.empty)}>
                  {isLoading ? (
                    <Indicator
                      loading={!error}
                      error={!!error}
                      className={styles.loader}
                    />
                  ) : (
                    <span>{t('no_results')}</span>
                  )}
                </div>
              )}
              {error && <div className={styles.error}>{error.message}</div>}
            </div>
          )}

          {children && (
            <div className={cx(styles.children)}>
              {typeof children === 'function'
                ? children({ onReset: handleReset, values })
                : children}
            </div>
          )}
        </div>
      </div>
    );
  }
);
