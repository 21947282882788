import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';
import { isPromise } from '../../../../Utils/isPromise';
import { Button } from '../../Buttons/Button';
import { useModal } from '../useModal';
import { ConfirmationModalProps } from './ConfirmationModalProps';
import styles from './ConfirmationModal.module.css';

const cx = classNames.bind(styles);

export const ConfirmationModal = ({
  children,
  className,
  style,
  title,
  question,
  description,
  yes,
  no,
  isDanger,
  icon,
  onClose,
  closeModal,
  onClickOutside,
  handleConfirm,
}: ConfirmationModalProps) => {
  const {
    handleClose,
    ref: modalElement,
    hasCloseButton,
    animate,
  } = useModal({
    onClose,
    closeable: true,
    closeModal,
    onClickOutside,
  });

  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);
  const handleConfirmation = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      setLoading(true);
      const confirmation = handleConfirm?.(event);
      if (isPromise(confirmation)) {
        confirmation.then(() => {
          if (mounted.current) {
            setLoading(false);
          }
        });
      } else {
        setLoading(false);
      }
    },
    [handleConfirm]
  );

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div
      className={cx(styles.base, { 'animate-in': animate }, className)}
      ref={modalElement}
      style={style}
    >
      {hasCloseButton && (
        <button
          type="button"
          disabled={loading}
          className={cx('btn-close')}
          aria-label="Sluiten | Close" // FixMe: Make button translated to language
          onClick={() => handleClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path
              className={cx(styles.cross)}
              d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
            />
          </svg>
        </button>
      )}
      <div className={cx(styles.content)}>
        {title && <h3 className={cx(styles.title)}>{title}</h3>}
        {(description || question) && <p>{description || question}</p>}
        {children}
      </div>
      {(yes || no) && (
        <div className={styles.footer}>
          {yes && (
            <Button
              error={isDanger}
              loading={loading}
              onClick={handleConfirmation}
            >
              {icon}
              <span>{yes}</span>
            </Button>
          )}
          {no && (
            <Button
              className={cx(styles.noButton, { isDestroy: isDanger })}
              disabled={loading}
              onClick={handleClose}
              outline={isDanger}
              quiet={!isDanger}
            >
              {no}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
